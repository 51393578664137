/* eslint-disable @typescript-eslint/no-unused-expressions */
import AddIcon from "@mui/icons-material/Add";
// import useItem from "../../services/queries/useItem";
import useUser from "../../services/queries/useUser";
import useUkm from "../../services/queries/useUkm";
import Spinner from "../Spinner/Spinner";
import SearchIcon from "@mui/icons-material/Search";
import CardBarang from "../CardBarang/CardBarang";
import useKategori from "../../services/queries/useKategori";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { useStokModal } from "../../context/stokModalContext";
import { ParamsGetItem, ParamsGetKategori } from "../../constants/types";
import { ReactComponent as BarangTidakDitemukan } from "../../assets/images/BarangTidakDitemukan.svg";
import {
    TextField,
    Button,
    Box,
    Typography,
    Stack,
    useMediaQuery,
    // Autocomplete,
    IconButton,
    InputAdornment,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTransaksi } from "../../context/transaksiContext";
import useInfiniteItem from "../../services/queries/useInfiniteItem";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { LoadingButton } from "@mui/lab";
import MenuBarang from "../MenuBarang/MenuBarang";
// import InfiniteScroll from "react-infinite-scroller";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import useUkmIsDueExpired from "../../services/queries/useUkmIsDueExpired";
import CardBarangMini from "../CardBarang/CardBarangMini";

interface IListBarangProps {
    isCardView: boolean;
    cardView: () => void;
    listView: () => void;
    handleIsError: () => void;
}

// interface ICart {
//   id: number;
//   namaBarang: string;
//   harga: number;
//   qty: number;
// }

// type IOptions = {
//     id: number;
//     nama: string;
// };

const ListBarang = ({
    isCardView,
    cardView,
    listView,
    handleIsError,
}: IListBarangProps) => {
    // console.count("rerender");
    // console.log("AYOOO");
    const { data: user, status: loadingUser } = useUser();
    const { ukmIdUser } = useAuth();
    const theme = useTheme();

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const initialParamsItem = {
        ukmId: undefined,
        size: 50,
        page: 1,
        isShow: 1,
        search: "",
        kategori: "",
    };

    const initialParamsKategori = {
        ukmId: undefined,
        size: 500,
        page: 1,
        search: "",
        kategori: "",
    };

    const [search, setSearch] = useState<string>("");
    const { openModal } = useStokModal();
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
    const [paramsKategori, setParamsKategori] = useState<ParamsGetKategori>(
        initialParamsKategori,
    );

    const {
        data: item,
        status: loadingItem,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isError: isErrorItem,
    } = useInfiniteItem(paramsItem);
    const {
        data: datakategori,
        status: loadingKategori,
        refetch: refetchKategori,
        isError: isErrorKategori,
    } = useKategori(paramsKategori);

    const initialParamsUkmIsDueExpired = React.useMemo(
        () => ({
            search: "",
            size: 50,
            page: 1,
            isDueExpired: 1,
        }),
        [],
    );
    const { data: ukmIsDueExpired } = useUkmIsDueExpired(
        initialParamsUkmIsDueExpired,
    );
    const [kategori, setKategori] = useState("");
    // const [selectedKategori, setSelectedKategori] = useState<IOptions | null>(
    //     null,
    // );

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const handleClickOpen = () => {
        openModal();
    };

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handleSearch = () => {
        setParamsItem((prev) => ({
            ...prev,
            search: search,
        }));
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsItem((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        if (kategori !== null) {
            // if (selectedKategori !== null) {
            setParamsItem((prev) => ({
                ...prev,
                kategori: kategori,
                // kategori: String(selectedKategori.id),
            }));
        } else {
            setParamsItem((prev) => ({
                ...prev,
                kategori: "",
            }));
        }
    }, [kategori]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        refetch({ refetchPage: (page, index) => index === 0 });
    }, [item, refetch]);

    useEffect(() => {
        if (isFetchingItems === true) {
            refetch();
            refetchKategori();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetch, refetchKategori]);

    useEffect(() => {
        if (isErrorItem || isErrorKategori) {
            handleIsError();
        }
    }, [handleIsError, isErrorItem, isErrorKategori]);

    return (
        <Box
            display="grid"
            gridTemplateRows="auto 1fr"
            sx={{
                height: isPhoneScreen
                    ? ukmData &&
                      ukmIsDueExpired &&
                      ukmIsDueExpired.totalElements > 0 &&
                      ukmIsDueExpired.content.filter(
                          (list) => list.id === ukmData.id,
                      ).length > 0
                        ? "calc(100vh - 146px)"
                        : "calc(100vh - 56px)"
                    : isLaptopScreen
                    ? ukmData &&
                      ukmIsDueExpired &&
                      ukmIsDueExpired.totalElements > 0 &&
                      ukmIsDueExpired.content.filter(
                          (list) => list.id === ukmData.id,
                      ).length > 0
                        ? "calc(100vh - 48.75px)"
                        : "100vh"
                    : "calc(100vh - 64px)",
            }}
        >
            <Box alignItems="center" margin={2}>
                <Stack
                    direction={{ xs: "column-reverse", lg: "row" }}
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Stack direction="row" spacing={1} width="100%">
                        <Box>
                            <TextField
                                placeholder="Cari barang..."
                                size="small"
                                fullWidth
                                value={search}
                                onChange={handleChangeSearch}
                                onKeyPress={(e) => {
                                    const element =
                                        e.target as HTMLInputElement;
                                    if (e.key === "Enter") {
                                        setParamsItem((prev) => ({
                                            ...prev,
                                            search: element.value,
                                            page: 1,
                                        }));
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                        </Box>
                        <Box
                            sx={{
                                width: { xs: "50%", sm: "50%" },
                                justifyContent: "flex-end",
                            }}
                        >
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-simple-select-helper-label">
                                    Kategori
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="Kategori"
                                    defaultValue=""
                                    onChange={(e) =>
                                        setKategori(String(e.target.value))
                                    }
                                >
                                    <MenuItem value="">Semua</MenuItem>
                                    {datakategori &&
                                        datakategori?.content.map((k) => (
                                            <MenuItem key={k.id} value={k.id}>
                                                {k.nama}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            {/* <Autocomplete
                                options={datakategori?.content ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Kategori"
                                        variant="outlined"
                                    />
                                )}
                                getOptionLabel={(option) => option.nama}
                                size="small"
                                fullWidth
                                value={selectedKategori}
                                onChange={(
                                    _event,
                                    newKategori: IOptions | null,
                                ) => {
                                    setSelectedKategori(newKategori);
                                }}
                            /> */}
                        </Box>
                    </Stack>
                    <Box
                        display={
                            item
                                ? ukmData
                                    ? ukmData.limitItem
                                        ? item.pages[0].results.totalItem >=
                                          ukmData.limitItem
                                            ? "none"
                                            : undefined
                                        : item.pages[0].results.totalItem >= 500
                                        ? "none"
                                        : undefined
                                    : "none"
                                : "none"
                        }
                    >
                        <Button
                            disabled={
                                user && !user.akses.transaksi.includes(11)
                            }
                            variant="contained"
                            onClick={handleClickOpen}
                            startIcon={<AddIcon />}
                            size={isTabletScreen ? "small" : "medium"}
                            sx={{
                                lineHeight: 2,
                            }}
                        >
                            Tambah
                        </Button>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    marginTop={3}
                    marginBottom={1}
                    alignItems="center"
                    // display={
                    //     isPhoneScreen || isTabletScreen ? "none" : undefined
                    // }
                >
                    <Button
                        size="small"
                        variant={isCardView ? "outlined" : "contained"}
                        onClick={listView}
                    >
                        <ViewListIcon />
                    </Button>
                    <Button
                        size="small"
                        variant={isCardView ? "contained" : "outlined"}
                        onClick={cardView}
                    >
                        <GridViewIcon />
                    </Button>
                </Stack>
            </Box>
            {loadingItem === "loading" ||
            loadingUser === "loading" ||
            loadingKategori === "loading" ? (
                <Box
                    position="relative"
                    height={
                        isLaptopScreen
                            ? "calc(100vh - 40px)"
                            : "calc(100vh - 56px)"
                    }
                >
                    <Spinner />
                </Box>
            ) : isCardView ? (
                <Box
                    className="hide-scroll"
                    marginX={2}
                    // sx={{
                    //     overflowY: "scroll",
                    // }}
                >
                    {item ? (
                        <React.Fragment>
                            {item.pages.map((page) =>
                                page.results.totalElements > 0 ? (
                                    <React.Fragment key={page.results.nextId}>
                                        <Box
                                            display="grid"
                                            gridTemplateColumns="repeat(auto-fill, minmax(150px, 1fr))"
                                            gap={3}
                                        >
                                            {page.results.content.map(
                                                (item) => (
                                                    <MenuBarang
                                                        key={item.id}
                                                        id={item.id}
                                                        sku={item?.sku}
                                                        namaBarang={
                                                            item.namaBarang
                                                        }
                                                        namaKategori={
                                                            item.kategori.nama
                                                        }
                                                        jumlahStok={item.stok}
                                                        deskripsi={
                                                            item.deskripsi
                                                        }
                                                        harga={item.harga}
                                                        url={item.gambar}
                                                        detailBundling={
                                                            item.detailBundling
                                                        }
                                                        favorite={item.favorite}
                                                        hpp={item.hpp}
                                                        multipleHarga={item.hargaMultiple.map(
                                                            (li) => ({
                                                                id: li.id,
                                                                harga: Number(
                                                                    li.harga,
                                                                ),
                                                                keterangan:
                                                                    li.keterangan,
                                                            }),
                                                        )}
                                                    />
                                                ),
                                            )}
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <Box
                                        display="flex"
                                        flex={1}
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        height="100%"
                                    >
                                        <BarangTidakDitemukan
                                            width="50%"
                                            style={{
                                                height: "fit-content",
                                            }}
                                        />
                                        <Typography
                                            marginTop={5}
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Barang tidak ditemukan
                                        </Typography>
                                    </Box>
                                ),
                            )}
                            {hasNextPage && (
                                <Box width="100%" paddingY={2}>
                                    <LoadingButton
                                        variant="outlined"
                                        onClick={() => fetchNextPage()}
                                        loading={isFetchingNextPage}
                                        loadingPosition="center"
                                        size="large"
                                        fullWidth
                                    >
                                        {hasNextPage
                                            ? "Muat lebih banyak"
                                            : "Tidak ada lagi yang bisa dimuat"}
                                    </LoadingButton>
                                </Box>
                            )}
                        </React.Fragment>
                    ) : (
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            <BarangTidakDitemukan
                                width="50%"
                                style={{ height: "fit-content" }}
                            />
                            <Typography
                                marginTop={5}
                                variant="h6"
                                fontWeight="bold"
                            >
                                Barang tidak ditemukan
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : (
                <Box
                    className="hide-scroll"
                    marginX={2}
                    // sx={{
                    //     overflowY: "scroll",
                    // }}
                >
                    {item ? (
                        <React.Fragment>
                            {item.pages.map((page) =>
                                page.results.totalElements > 0 ? (
                                    <React.Fragment key={page.results.nextId}>
                                        {page.results.content.map((item) =>
                                            isTabletScreen ? (
                                                <CardBarangMini
                                                    key={item.id}
                                                    id={item.id}
                                                    sku={item?.sku}
                                                    namaBarang={item.namaBarang}
                                                    namaKategori={
                                                        item.kategori.nama
                                                    }
                                                    jumlahStok={item.stok}
                                                    deskripsi={item.deskripsi}
                                                    harga={item.harga}
                                                    url={item.gambar}
                                                    detailBundling={
                                                        item.detailBundling
                                                    }
                                                    favorite={item.favorite}
                                                    hpp={item.hpp}
                                                    multipleHarga={item.hargaMultiple.map(
                                                        (li) => ({
                                                            id: li.id,
                                                            harga: Number(
                                                                li.harga,
                                                            ),
                                                            keterangan:
                                                                li.keterangan,
                                                        }),
                                                    )}
                                                />
                                            ) : (
                                                <CardBarang
                                                    key={item.id}
                                                    id={item.id}
                                                    sku={item?.sku}
                                                    namaBarang={item.namaBarang}
                                                    namaKategori={
                                                        item.kategori.nama
                                                    }
                                                    jumlahStok={item.stok}
                                                    deskripsi={item.deskripsi}
                                                    harga={item.harga}
                                                    url={item.gambar}
                                                    detailBundling={
                                                        item.detailBundling
                                                    }
                                                    favorite={item.favorite}
                                                    hpp={item.hpp}
                                                    multipleHarga={item.hargaMultiple.map(
                                                        (li) => ({
                                                            id: li.id,
                                                            harga: Number(
                                                                li.harga,
                                                            ),
                                                            keterangan:
                                                                li.keterangan,
                                                        }),
                                                    )}
                                                />
                                            ),
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Box
                                        display="flex"
                                        flex={1}
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        height="100%"
                                    >
                                        <BarangTidakDitemukan
                                            width="50%"
                                            style={{ height: "fit-content" }}
                                        />
                                        <Typography
                                            marginTop={5}
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Barang tidak ditemukan
                                        </Typography>
                                    </Box>
                                ),
                            )}
                            {hasNextPage && (
                                <Box
                                    width="100%"
                                    paddingY={isTabletScreen ? 1 : 2}
                                >
                                    <LoadingButton
                                        variant="outlined"
                                        onClick={() => fetchNextPage()}
                                        loading={isFetchingNextPage}
                                        loadingPosition="center"
                                        size={
                                            isTabletScreen ? "small" : "large"
                                        }
                                        fullWidth
                                    >
                                        {hasNextPage
                                            ? "Muat lebih banyak"
                                            : "Tidak ada lagi yang bisa dimuat"}
                                    </LoadingButton>
                                </Box>
                            )}
                        </React.Fragment>
                    ) : (
                        // <InfiniteScroll
                        //     hasMore={hasNextPage}
                        //     loadMore={fetchNextPage}
                        // >
                        //     {item.pages.map((page) =>
                        //         page.results.content.map((item) => (
                        //             <CardBarang
                        //                 key={item.id}
                        //                 id={item.id}
                        //                 sku={item?.sku}
                        //                 namaBarang={item.namaBarang}
                        //                 jumlahStok={item.stok}
                        //                 deskripsi={item.deskripsi}
                        //                 harga={item.harga}
                        //                 url={item.gambar}
                        //                 detailBundling={item.detailBundling}
                        //             />
                        //         )),
                        //     )}
                        // </InfiniteScroll>
                        <Box
                            display="flex"
                            flex={1}
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="100%"
                        >
                            <BarangTidakDitemukan
                                width="50%"
                                style={{ height: "fit-content" }}
                            />
                            <Typography
                                marginTop={5}
                                variant="h6"
                                fontWeight="bold"
                            >
                                Barang tidak ditemukan
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default React.memo(ListBarang);
