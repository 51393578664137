import React, { useState } from "react";
import { useTransaksi } from "../../context/transaksiContext";
import {
    Card,
    Button,
    Box,
    Stack,
    Typography,
    TextField,
    Avatar,
    Grid,
    useMediaQuery,
    Tooltip,
    SelectChangeEvent,
    Select,
    MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import toRupiah from "../../utils/toRupiah";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import avatarAlt from "../../utils/avatarAlt";
import { DetailBundling, IFaktur } from "../../constants/types";
import "./CardPesan.css";
import truncate from "../../utils/truncate";
interface ICardPesanProps {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku: string;
    harga: number;
    url: string;
    qty: number;
    jumlahStok: number | undefined;
    detailBundling: DetailBundling[] | [];
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

interface ICart {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku: string;
    harga: number;
    url: string;
    qty: number;
    jumlahStok: number | undefined;
    detailBundling: DetailBundling[] | [];
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    faktur: IFaktur[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

const CardPesan = ({
    id,
    namaBarang,
    namaKategori,
    sku,
    harga,
    url,
    qty,
    jumlahStok,
    detailBundling,
    hpp,
    multipleHarga,
}: ICardPesanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    // const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const { handleUpdate, handleRemove } = useTransaksi();
    const [selectedHarga, setSelectedHarga] = useState(
        multipleHarga.length > 0 ? multipleHarga[0].harga : harga,
    );

    let valueQty = "0";
    let strQty = String(qty).replace(/^0+/, "");
    if (strQty[0] === "") {
        valueQty = "0";
    } else {
        valueQty = String(qty);
    }

    const addToCart = (data: ICart) => {
        if (data.qty >= 0) {
            handleUpdate({ ...data });
        } else {
            handleRemove({ ...data });
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedHarga(Number(event.target.value));
        addToCart({
            id,
            namaBarang,
            namaKategori,
            sku,
            harga: Number(event.target.value),
            url,
            jumlahStok,
            qty,
            detailBundling,
            hpp,
            faktur: [],
            multipleHarga,
        });
    };

    const incNum = () => {
        if (Number(jumlahStok) > qty || jumlahStok === null) {
            addToCart({
                id,
                namaBarang,
                namaKategori,
                sku,
                harga,
                url,
                jumlahStok,
                qty: qty + 1,
                detailBundling,
                hpp,
                faktur: [],
                multipleHarga,
            });
        }
    };

    const decNum = () => {
        if (qty >= 0) {
            addToCart({
                id,
                namaBarang,
                namaKategori,
                sku,
                harga,
                jumlahStok,
                url,
                qty: qty - 1,
                detailBundling,
                hpp,
                faktur: [],
                multipleHarga,
            });
        } else {
            addToCart({
                id,
                namaBarang,
                namaKategori,
                sku,
                harga,
                jumlahStok,
                url,
                qty: -1,
                detailBundling,
                hpp,
                faktur: [],
                multipleHarga,
            });
        }
    };

    const detailItemSatuan = detailBundling
        .map((li, idx, arr) => `${li.namaBarang} (${li.jumlahYangDiambil})`)
        .join(", ");

    // const handlerMaxItem = () => {
    //     if (qty > Number(jumlahStok) && jumlahStok !== null) {
    //         setErrorMaxItem(true);
    //         handlerErrorMaxItem();
    //     } else {
    //         setErrorMaxItem(false);
    //         resetErrorMaxItem();
    //     }
    // };

    // useEffect(() => {
    //     handlerMaxItem();
    // }, [qty]);

    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 1,
                padding: isTabletScreen ? 1 : 2,
            }}
        >
            <Box display="grid" className="card-pesan">
                <Stack
                    direction="row"
                    spacing={isTabletScreen ? 0.5 : 1}
                    alignItems="flex-start"
                    gridColumn={isPhoneScreen ? "1 / 3" : "unset"}
                >
                    <Avatar
                        sx={{
                            display: isTabletScreen ? "none" : undefined,
                            backgroundColor: "primary.main",
                            width: 65,
                            height: 70,
                        }}
                        variant="rounded"
                        alt={namaBarang}
                        src={url}
                    >
                        {avatarAlt(namaBarang)}
                    </Avatar>
                    <Box flexDirection="column" width="100%">
                        <Tooltip title={namaBarang}>
                            <Typography
                                variant={isTabletScreen ? "body2" : "subtitle2"}
                                fontWeight={isTabletScreen ? 500 : "bold"}
                            >
                                {truncate(namaBarang, 20)}
                            </Typography>
                        </Tooltip>
                        <Typography fontSize={12} color="#76747C">
                            {detailItemSatuan}
                        </Typography>
                        <Grid container my={isTabletScreen ? 0.5 : 1}>
                            <Grid item>
                                {multipleHarga.length > 1 ? (
                                    <Select
                                        placeholder="Pilih Stok"
                                        margin="dense"
                                        size="small"
                                        onChange={handleChange}
                                        value={String(selectedHarga)}
                                        sx={{
                                            height: "14px",
                                            minHeight: "unset",
                                            fontSize: "12px",
                                            paddingY: "14px",
                                        }}
                                        fullWidth
                                    >
                                        {multipleHarga.map((li) => (
                                            <MenuItem value={li.harga}>
                                                {`${toRupiah(
                                                    Number(li.harga),
                                                )} ${
                                                    li.keterangan === "-''-" ||
                                                    li.keterangan === null
                                                        ? ""
                                                        : `(${li.keterangan})`
                                                }`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : multipleHarga.length === 1 ? (
                                    <Typography
                                        fontSize={12}
                                        color="#76747C"
                                        fontWeight="bold"
                                    >
                                        {`${toRupiah(
                                            Number(multipleHarga[0].harga),
                                        )}`}
                                    </Typography>
                                ) : (
                                    <Typography
                                        fontSize={12}
                                        color="#76747C"
                                        fontWeight="bold"
                                    >
                                        {toRupiah(harga)}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {multipleHarga.length > 0 ? (
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                fontWeight="bold"
                            >
                                {toRupiah(Number(selectedHarga) * qty)}
                            </Typography>
                        ) : (
                            <Typography
                                variant="subtitle2"
                                color="primary"
                                fontWeight="bold"
                            >
                                {toRupiah(harga * qty)}
                            </Typography>
                        )}
                    </Box>
                </Stack>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <Stack
                        direction="row"
                        width="100%"
                        spacing={1}
                        gridColumn={isPhoneScreen ? "1/3" : "unset"}
                        display="grid"
                        gridTemplateColumns="auto 1fr auto"
                    >
                        <Button
                            onClick={decNum}
                            variant="outlined"
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                            // sx={{ minWidth: 30, minHeight: 30 }}
                        >
                            {qty === 0 ? (
                                <DeleteForeverIcon color="primary" />
                            ) : (
                                <RemoveIcon color="primary" />
                            )}
                        </Button>
                        <TextField
                            value={valueQty}
                            type="number"
                            onWheel={(e) => {
                                const target = e.target as HTMLButtonElement;
                                if (target) {
                                    target.blur();
                                    e.stopPropagation();
                                    setTimeout(() => {
                                        target.focus();
                                    }, 0);
                                }
                            }}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            size="small"
                            // sx={{ minWidth: 60, minHeight: 30 }}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                addToCart({
                                    id,
                                    namaBarang,
                                    namaKategori,
                                    sku,
                                    harga,
                                    url,
                                    jumlahStok,
                                    qty: Number(event.target.value),
                                    detailBundling,
                                    hpp,
                                    faktur: [],
                                    multipleHarga,
                                });
                            }}
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    minWidth: "40px",
                                },
                            }}
                        />
                        <Button
                            disabled={
                                qty >= Number(jumlahStok) && jumlahStok !== null
                            }
                            onClick={incNum}
                            variant="contained"
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                        >
                            <AddIcon color="inherit" />
                        </Button>
                    </Stack>
                    <Grid mt={1}>
                        <Typography
                            fontSize={10}
                            color="#76747C"
                            fontWeight="bold"
                        >
                            {jumlahStok !== null
                                ? "max. " + jumlahStok
                                : "Tanpa Stok"}
                        </Typography>
                    </Grid>
                </Box>
            </Box>
            {qty > Number(jumlahStok) &&
                jumlahStok !== null &&
                qty !== null && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Typography
                            color="red"
                            variant="subtitle2"
                            fontWeight="bold"
                        >
                            Jumlah pesanan melebihi stok
                        </Typography>
                    </Box>
                )}
        </Card>
    );
};

export default CardPesan;
